import { isRouteErrorResponse, useLoaderData, useRouteError } from '@remix-run/react';
import { Container } from '@storefront/ui-components/shared/container';
import type { LoaderFunctionArgs } from '@vercel/remix';
import { NavbarContent } from '~/components/navbar/NavbarContent';
import { userPostalCode } from '~/utils/server/postal-code.server';
import { GenericError } from '../../libs/ui-components/errors/generic-error';
import { NotFound } from '../../libs/ui-components/errors/not-found';
import { AuctionListWithPagination } from '../components/auctions/AuctionListWithPagination';
import { DEFAULT_AUCTIONS_PAGE_SIZE } from '../components/auctions/config';
import { withErrorHandling } from '../utils/loader-wrapper';
import { withAuctions } from '../utils/server/auctions.server';

export const loader = withErrorHandling(async (loaderFunctionArgs: LoaderFunctionArgs) => {
  const userLocation = await userPostalCode(loaderFunctionArgs.request);
  const postalCode = typeof userLocation === 'string' ? userLocation : userLocation?.postalCode;

  const options = {
    postal_code: postalCode,
    limit: DEFAULT_AUCTIONS_PAGE_SIZE,
  };

  const { auctions, count, limit, offset } = await withAuctions(loaderFunctionArgs, options);

  return {
    auctions,
    count,
    limit,
    offset,
  };
});

export type HomepageIndexRouteLoader = typeof loader;

export default function IndexRoute() {
  const data = useLoaderData<HomepageIndexRouteLoader>();

  if (!data) {
    throw new Error('No data');
  }

  const { auctions, count, limit, offset } = data;

  return (
    <>
      <NavbarContent />

      <Container className="pb-16">
        <div className="flex flex-col gap-4 sm:flex-row">
          <div className="flex-1">
            <AuctionListWithPagination
              auctions={auctions}
              paginationConfig={{ count, offset, limit }}
              context="products"
            />
          </div>
        </div>
      </Container>
    </>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();

  if (isRouteErrorResponse(error) && error.status === 404) return <NotFound />;

  console.error('error boundary catch all error', error);

  return <GenericError />;
}
